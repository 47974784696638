export const baseImgixURL = `${process.env.IMGIX_ENDPOINT}/`
export const reactRevealDuration = 500 // ms
export const reactRevealDistance = '50%'
export const timelineY = {
  start: 0,
  end: 50,
}
export const staggerDelay = 200
export const cubicBezier = 'cubic-bezier(0.6, 0.2, 0.1, 1)'
export const companyEmail = `info@dailyquestplus.co.uk`
export const faisalEmail = `faisal@dailyquestplus.co.uk`
export const preloadImgs = [
  {
    route: 'howitworks',
    imgs: [
      {
        name: 'paint',
        sizes: {
          lg: {
            width: 831,
          },
          md: {
            width: 600,
          },
        },
      },
      {
        name: 'books',
        sizes: {
          lg: {
            width: 988,
          },
          md: {
            width: 500,
          },
        },
      },
      {
        name: 'mobile',
        sizes: {
          lg: {
            width: 564,
          },
          md: {
            width: 350,
          },
        },
      },
    ],
  },
  {
    route: 'pricing',
    imgs: [
      {
        name: 'rocket',
        sizes: {
          lg: {
            width: 466,
          },
          md: {
            width: 380,
          },
        },
      },
      {
        name: 'clip',
        sizes: {
          lg: {
            width: 143,
          },
          md: {
            width: 130,
          },
        },
      },
      {
        name: 'scissors',
        sizes: {
          lg: {
            width: 443,
          },
          md: {
            width: 380,
          },
        },
      },
    ],
  },
  {
    route: 'about',
    imgs: [
      {
        name: 'cog',
        sizes: {
          lg: {
            width: 700,
          },
          md: {
            width: 480,
          },
        },
      },
      {
        name: 'popcorn',
        sizes: {
          lg: {
            width: 686,
          },
          md: {
            width: 500,
          },
        },
      },
    ],
  },
]
