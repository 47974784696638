import React from 'react'
import { Global } from '@emotion/core'
import PropTypes from 'prop-types'
import '@dqp/common/styles/app.scss'
import globals from '@dqp/common/styles/index'
import PreloadImages from '#components/PreloadImages'

export const wrapRootElement = ({ element }) => (
  <>
    <Global styles={globals} />
    {element}
    <PreloadImages />
  </>
)

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}
