import React, { useEffect, useState } from 'react'
import Image from './Image'
import { preloadImgs } from '#globals/constants'

function PreloadImages() {
  const [load, setLoad] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLoad(true)
    }, 2000)
  }, [])
  return !load ? null : (
    <div className='d-none'>
      {preloadImgs.map(({ route, imgs }) =>
        imgs.map(image => (
          <Image
            key={image.name}
            className='d-none d-md-block'
            alt='paint bucket'
            image={`${route}/${image.name}`}
            sizes={image.sizes}
          />
        )),
      )}
    </div>
  )
}

export default PreloadImages
