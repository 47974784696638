// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-advice-jsx": () => import("./../src/components/templates/advice.jsx" /* webpackChunkName: "component---src-components-templates-advice-jsx" */),
  "component---src-components-templates-advice-page-jsx": () => import("./../src/components/templates/advicePage.jsx" /* webpackChunkName: "component---src-components-templates-advice-page-jsx" */),
  "component---src-components-templates-advice-page-per-category-jsx": () => import("./../src/components/templates/advicePagePerCategory.jsx" /* webpackChunkName: "component---src-components-templates-advice-page-per-category-jsx" */),
  "component---src-components-templates-advice-page-per-tag-jsx": () => import("./../src/components/templates/advicePagePerTag.jsx" /* webpackChunkName: "component---src-components-templates-advice-page-per-tag-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-it-works-js": () => import("./../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

